/** @jsx jsx */
import { jsx } from 'theme-ui';

import { graphql } from 'gatsby';
import Layout from 'components/layout';
import {
  Header1, Header5, Header4,
} from 'components/primitives/text';
import _ from 'lodash';
import JobApplicationForm from 'components/forms/jobApplication';

const JobTemplate = ({ data }: any) => {
  const { markdownRemark } = data;
  if (_.isNull(markdownRemark)) {
    return null;
  }
  const { frontmatter, html } = markdownRemark;


  return (
    <Layout
      title={`${frontmatter.position}, ${frontmatter.omfattning}`}
      seoDescription={frontmatter.seoDescription}
    >
      <Header1 sx={{ mb: 1 }}>
        {frontmatter.position}
      </Header1>
      <Header4>{frontmatter.omfattning}</Header4>
      <Header5 sx={{ mb: 0 }}>Publicerad</Header5>
      <Header5 className="highlight" sx={{ mb: 5 }}>{frontmatter.date}</Header5>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <JobApplicationForm jobTitle={frontmatter.position} />
    </Layout>
  );
};

export default JobTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "dddd Do MMMM, YYYY", locale: "sv_se")
        slug
        position
        omfattning
        seoDescription
      }
    }
  }
`;
