/** @jsx jsx */
import {
  jsx, Input, Label, Textarea, Flex,
} from 'theme-ui';
import React, { useState } from 'react';
import { PrimaryButton } from 'components/layout/button';
import { Header1, Header2, HighlightText } from 'components/primitives/text';
// import Recaptcha from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import _ from 'lodash';
import * as yup from 'yup';
import { encode } from 'utils/helper';


interface FormInputs {
  'form-name': string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cv: any;
  description: string;
  // 'g-recaptcha-response': string;
}

const SUPPORTED_FILES = ['application/pdf'];

const schema = yup.object().shape({
  firstName: yup.string().required('Obligatoriskt'),
  lastName: yup.string().required('Obligatoriskt'),
  email: yup.string().email('Ogiltig epostadress').required('Obligatoriskt'),
  phone: yup.string().required('Obligatoriskt'),
  cv: yup.mixed().required('Obligatoriskt').test('fileType', 'Filformatet stöds ej', (value) => (value[0] ? SUPPORTED_FILES.includes(value[0].type) : false)),
  description: yup.string(),
  // 'g-recaptcha-response': yup.string().required('Obligatoriskt'),
});

const ThankYou = () => (
  <Flex
    sx={{
      py: 8,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Header2>
      <HighlightText>{'{{Tack}} för din ansökan! 🙏'}</HighlightText>
    </Header2>
    <p>Din ansökan har skickats och kommer behandlas inom kort.</p>
  </Flex>
);

const JobApplicationForm = ({
  jobTitle,
}: {
  jobTitle: string;
}) => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });


  // const handleRecaptcha = (value: any) => {
  //   setValue('g-recaptcha-response', value);
  // };

  const onSubmit = (data: FormInputs) => {
    const cvFile = data.cv[0];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { cv, ...formData } = data;

    setLoading(true);

    fetch('/', {
      method: 'POST',
      body: encode({
        cv: cvFile,
        ...formData,
      }),
    })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <hr />
      <Header1 sx={{ mt: 5, mb: 0, textAlign: 'center' }}>Ansökan</Header1>
      {
      isSubmitted
        ? <ThankYou />
        : (
          <form
            sx={{
              position: 'relative',
              zIndex: 9999,
              mt: 5,
              px: [0, 0, 0, 0, 8],
              opacity: isLoading ? 0.5 : 1,
            }}
            name={`Jobbansökan - ${jobTitle}`}
            method="post"
            action="/"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={handleSubmit(onSubmit)}
          >
            <noscript>
              <p>This form won’t work with Javascript disabled</p>
            </noscript>
            <p
              sx={{
                visibility: 'hidden',
                lineHeight: 0,
                margin: 0,
                height: 0,
              }}
            >
              <label htmlFor="bot-field">Don’t fill this out if you&apos;re human: <input name="bot-field" /></label>
            </p>
            <input type="hidden" ref={register} name="form-name" value={`Jobbansökan - ${jobTitle}`} />
            <p sx={{
              textAlign: 'right',
              fontSize: 1,
              mb: 0,
              color: 'muted',
              fontWeight: 'body',
            }}
            >
              * Obligatoriskt fält
            </p>

            <div
              sx={{
                display: 'grid',
                gridGap: [0, 0, 4],
                gridTemplateColumns: [
                  [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr 1fr',
                  ],
                ],
              }}
            >
              <div>
                <Label htmlFor="firstName">Förnamn *</Label>
                <Input
                  type="text"
                  name="firstName"
                  className={classNames({ 'has-error': !_.isUndefined(errors.firstName) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.firstName?.message}</p>
              </div>
              <div>
                <Label htmlFor="lastName">Efternamn *</Label>
                <Input
                  type="text"
                  name="lastName"
                  className={classNames({ 'has-error': !_.isUndefined(errors.lastName) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.lastName?.message}</p>
              </div>
            </div>
            <div
              sx={{
                display: 'grid',
                gridGap: [0, 0, 4],
                gridTemplateColumns: [
                  [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr',
                  ], [
                    'auto',
                    '1fr 1fr',
                  ],
                ],
              }}
            >
              <div>
                <Label htmlFor="email">E-postadress *</Label>
                <Input
                  type="text"
                  name="email"
                  className={classNames({ 'has-error': !_.isUndefined(errors.email) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.email?.message}</p>
              </div>
              <div>
                <Label htmlFor="phone">Telefon *</Label>
                <Input
                  type="text"
                  name="phone"
                  className={classNames({ 'has-error': !_.isUndefined(errors.phone) })}
                  ref={register}
                />
                <p className="error-message" sx={{ mb: 4 }}>{errors.phone?.message}</p>
              </div>
            </div>
            <Label sx={{ alignItems: 'flex-end' }} htmlFor="cv">CV <small sx={{ mx: 1 }}>(PDF format)</small> *</Label>
            <Input
              type="file"
              name="cv"
              className={classNames({ 'has-error': !_.isUndefined(errors.cv) })}
              ref={register}
            />
            <p className="error-message" sx={{ mb: 4 }}>{errors.cv?.message}</p>

            <Label htmlFor="description">Fritext</Label>
            <Textarea
              name="description"
              rows={5}
              ref={register}
            />
            <p className="error-message" sx={{ mb: 4 }}>{errors.description?.message}</p>

            {/* <Recaptcha
              sitekey="6LcHsq4ZAAAAAEMWxZFdCHRPVswN9KAxFiaXMz-W"
              onChange={handleRecaptcha}
            /> */}
            <PrimaryButton
              icon="envelope-open-text"
              sx={{ m: '0 auto', my: [7, 7, 6, 6, 6] }}
              type="submit"
              disabled={isLoading}
            >
              Skicka ansökan
            </PrimaryButton>
          </form>
        )
      }

    </React.Fragment>
  );
};

export default JobApplicationForm;
